import React from "react";

export default function SupportedBlockchains() {
	return (
		<footer>
			<div className="container">
				<img src="/assets/imgs/logos/moonbridge-full-w.png" alt="Moonbridge logo" />
				<p>Polkadot Hackathon: North America Edition</p>
			</div>
		</footer>
		);
}