const txResponse = [
    {
        "txid": "0xda844609444798c9d089e21021a1d24d4b1df995563ea83b1db3f3012c10140b",
        "swaptx": "0xa53a204ca0c4605efa42c99338c6fa2a80a201a87166412e8e71a6919a3f1522",
        "srcChainId": "43114",
        "destChainId": "1284",
        "from": "0xbc91222c7db724f9aa99eff7b493ec37ff241341",
        "timestamp": 1656726655,
        "token": "AVAX",
        "formatvalue": "0.06",
        "formatswapvalue": "0.06",
        "status": "Complete",
        "bridge": "Multichain"
    },
    {
        "txid": "0xda844609444798c9d089e21021a1d24d4b1df995563ea83b1db3f3012c10140b",
        "swaptx": "0xa53a204ca0c4605efa42c99338c6fa2a80a201a87166412e8e71a6919a3f1522",
        "srcChainId": "43114",
        "destChainId": "1284",
        "from": "0xbc91222c7db724f9aa99eff7b493ec37ff241341",
        "timestamp": 1656726655,
        "token": "AVAX",
        "formatvalue": "0.06",
        "formatswapvalue": "0.06",
        "status": "Complete",
        "bridge": "Multichain"
    },
    {
        "txid": "0xda844609444798c9d089e21021a1d24d4b1df995563ea83b1db3f3012c10140b",
        "swaptx": "0xa53a204ca0c4605efa42c99338c6fa2a80a201a87166412e8e71a6919a3f1522",
        "srcChainId": "43114",
        "destChainId": "1284",
        "from": "0xbc91222c7db724f9aa99eff7b493ec37ff241341",
        "timestamp": 1656726655,
        "token": "AVAX",
        "formatvalue": "0.06",
        "formatswapvalue": "0.06",
        "status": "Complete",
        "bridge": "Multichain"
    },
    {
        "txid": "0xb740f607ef507eaa73515caeb0c24e19c3f49754be029ed47ef7f376fe6decda",
        "swaptx": "0x5e669ea84b88c66de24d4272a26924c4c95b86cdbc655717a1f23290eedf754d",
        "srcChainId": "1284",
        "destChainId": "43114",
        "from": "0xbC91222C7DB724F9aA99eFf7b493Ec37FF241341",
        "timestamp": 1657000950,
        "token": "AVAX",
        "formatvalue": 0.09,
        "formatswapvalue": 0.04,
        "status": "Complete",
        "bridge": "Synapse"
    },
    {
        "txid": "0xda844609444798c9d089e21021a1d24d4b1df995563ea83b1db3f3012c10140b",
        "swaptx": "0xa53a204ca0c4605efa42c99338c6fa2a80a201a87166412e8e71a6919a3f1522",
        "srcChainId": "43114",
        "destChainId": "1284",
        "from": "0xbc91222c7db724f9aa99eff7b493ec37ff241341",
        "timestamp": 1656726655,
        "token": "AVAX",
        "formatvalue": "0.06",
        "formatswapvalue": "0.06",
        "status": "Complete",
        "bridge": "Multichain"
    },
    {
        "txid": "0xda844609444798c9d089e21021a1d24d4b1df995563ea83b1db3f3012c10140b",
        "swaptx": "0xa53a204ca0c4605efa42c99338c6fa2a80a201a87166412e8e71a6919a3f1522",
        "srcChainId": "43114",
        "destChainId": "1284",
        "from": "0xbc91222c7db724f9aa99eff7b493ec37ff241341",
        "timestamp": 1656726655,
        "token": "AVAX",
        "formatvalue": "0.06",
        "formatswapvalue": "0.06",
        "status": "Complete",
        "bridge": "Multichain"
    },
    {
        "txid": "0xda844609444798c9d089e21021a1d24d4b1df995563ea83b1db3f3012c10140b",
        "swaptx": "0xa53a204ca0c4605efa42c99338c6fa2a80a201a87166412e8e71a6919a3f1522",
        "srcChainId": "43114",
        "destChainId": "1284",
        "from": "0xbc91222c7db724f9aa99eff7b493ec37ff241341",
        "timestamp": 1656726655,
        "token": "AVAX",
        "formatvalue": "0.06",
        "formatswapvalue": "0.06",
        "status": "Complete",
        "bridge": "Multichain"
    },
    {
        "txid": "0xda844609444798c9d089e21021a1d24d4b1df995563ea83b1db3f3012c10140b",
        "swaptx": "0xa53a204ca0c4605efa42c99338c6fa2a80a201a87166412e8e71a6919a3f1522",
        "srcChainId": "43114",
        "destChainId": "1284",
        "from": "0xbc91222c7db724f9aa99eff7b493ec37ff241341",
        "timestamp": 1656726655,
        "token": "AVAX",
        "formatvalue": "0.06",
        "formatswapvalue": "0.06",
        "status": "Complete",
        "bridge": "Multichain"
    },
    {
        "txid": "0xda844609444798c9d089e21021a1d24d4b1df995563ea83b1db3f3012c10140b",
        "swaptx": "0xa53a204ca0c4605efa42c99338c6fa2a80a201a87166412e8e71a6919a3f1522",
        "srcChainId": "43114",
        "destChainId": "1284",
        "from": "0xbc91222c7db724f9aa99eff7b493ec37ff241341",
        "timestamp": 1656726655,
        "token": "AVAX",
        "formatvalue": "0.06",
        "formatswapvalue": "0.06",
        "status": "Complete",
        "bridge": "Multichain"
    },
    {
        "txid": "0xda844609444798c9d089e21021a1d24d4b1df995563ea83b1db3f3012c10140b",
        "swaptx": "0xa53a204ca0c4605efa42c99338c6fa2a80a201a87166412e8e71a6919a3f1522",
        "srcChainId": "43114",
        "destChainId": "1284",
        "from": "0xbc91222c7db724f9aa99eff7b493ec37ff241341",
        "timestamp": 1656726655,
        "token": "AVAX",
        "formatvalue": "0.06",
        "formatswapvalue": "0.06",
        "status": "Complete",
        "bridge": "Multichain"
    },
    {
        "txid": "0xda844609444798c9d089e21021a1d24d4b1df995563ea83b1db3f3012c10140b",
        "swaptx": "0xa53a204ca0c4605efa42c99338c6fa2a80a201a87166412e8e71a6919a3f1522",
        "srcChainId": "43114",
        "destChainId": "1284",
        "from": "0xbc91222c7db724f9aa99eff7b493ec37ff241341",
        "timestamp": 1656726655,
        "token": "AVAX",
        "formatvalue": "0.06",
        "formatswapvalue": "0.06",
        "status": "Complete",
        "bridge": "Multichain"
    },
    {
        "txid": "0xda844609444798c9d089e21021a1d24d4b1df995563ea83b1db3f3012c10140b",
        "swaptx": "0xa53a204ca0c4605efa42c99338c6fa2a80a201a87166412e8e71a6919a3f1522",
        "srcChainId": "43114",
        "destChainId": "1284",
        "from": "0xbc91222c7db724f9aa99eff7b493ec37ff241341",
        "timestamp": 1656726655,
        "token": "AVAX",
        "formatvalue": "0.06",
        "formatswapvalue": "0.06",
        "status": "Complete",
        "bridge": "Multichain"
    },
    {
        "txid": "0xda844609444798c9d089e21021a1d24d4b1df995563ea83b1db3f3012c10140b",
        "swaptx": "0xa53a204ca0c4605efa42c99338c6fa2a80a201a87166412e8e71a6919a3f1522",
        "srcChainId": "43114",
        "destChainId": "1284",
        "from": "0xbc91222c7db724f9aa99eff7b493ec37ff241341",
        "timestamp": 1656726655,
        "token": "AVAX",
        "formatvalue": "0.06",
        "formatswapvalue": "0.06",
        "status": "Complete",
        "bridge": "Multichain"
    },
    {
        "txid": "0xda844609444798c9d089e21021a1d24d4b1df995563ea83b1db3f3012c10140b",
        "swaptx": "0xa53a204ca0c4605efa42c99338c6fa2a80a201a87166412e8e71a6919a3f1522",
        "srcChainId": "43114",
        "destChainId": "1284",
        "from": "0xbc91222c7db724f9aa99eff7b493ec37ff241341",
        "timestamp": 1656726655,
        "token": "AVAX",
        "formatvalue": "0.06",
        "formatswapvalue": "0.06",
        "status": "Complete",
        "bridge": "Multichain"
    },
    {
        "txid": "0xda844609444798c9d089e21021a1d24d4b1df995563ea83b1db3f3012c10140b",
        "swaptx": "0xa53a204ca0c4605efa42c99338c6fa2a80a201a87166412e8e71a6919a3f1522",
        "srcChainId": "43114",
        "destChainId": "1284",
        "from": "0xbc91222c7db724f9aa99eff7b493ec37ff241341",
        "timestamp": 1656726655,
        "token": "AVAX",
        "formatvalue": "0.06",
        "formatswapvalue": "0.06",
        "status": "Complete",
        "bridge": "Multichain"
    }

]

export default txResponse;