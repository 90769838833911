const response  = [
    {
        "bridge": "Synapse",
        "srcChainID": "1",
        "logoUrl": "https://assets.coingecko.com/coins/images/18024/small/syn.png",
        "name": "Synapse",
        "symbol": "SYN",
        "pairid": "syn1Synapse",
        "SrcToken": {
            "Name": "Synapse",
            "Symbol": "SYN",
            "Decimals": 18,
            "ContractAddress": "0x0f2d719407fdbeff09d87557abb7232601fd9f29",
            "ContractInfo": {
                "SwapFee": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/estimate_bridge_output",
                    "fromChain": "1",
                    "toChain": "1284",
                    "fromToken": "SYN",
                    "toToken": "SYN",
                    "amountFrom": ""
                },
                "BridgeApproval": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_approval_txn",
                    "params": {
                        "fromChain": "1",
                        "fromToken": "SYN"
                    }
                },
                "BridgeTransaction": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_txn",
                    "params": {
                        "fromChain": "1",
                        "toChain": "1284",
                        "fromToken": "SYN",
                        "toToken": "SYN",
                        "amountFrom": "",
                        "addressTo": ""
                    }
                }
            }
        },
        "DestToken": {
            "Name": "Synapse",
            "Symbol": "SYN",
            "ContractAddress": "0xF44938b0125A6662f9536281aD2CD6c499F22004",
            "Decimals": 18,
            "ContractInfo": {
                "SwapFee": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/estimate_bridge_output",
                    "fromChain": "1284",
                    "toChain": "1",
                    "fromToken": "SYN",
                    "toToken": "SYN",
                    "amountFrom": ""
                },
                "BridgeApproval": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_approval_txn",
                    "params": {
                        "fromChain": "1284",
                        "fromToken": "SYN"
                    }
                },
                "BridgeTransaction": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_txn",
                    "params": {
                        "fromChain": "1284",
                        "toChain": "1",
                        "fromToken": "SYN",
                        "toToken": "SYN",
                        "amountFrom": "",
                        "addressTo": ""
                    }
                }
            }
        }
    },
    {
        "bridge": "Synapse",
        "srcChainID": "1",
        "logoUrl": "https://assets.coingecko.com/coins/images/21129/small/token_wsOHM_logo.png",
        "name": "Olympus DAO",
        "symbol": "gOHM",
        "pairid": "gohm1Synapse",
        "SrcToken": {
            "Name": "Olympus DAO",
            "Symbol": "gOHM",
            "Decimals": 18,
            "ContractAddress": "0x0ab87046fBb341D058F17CBC4c1133F25a20a52f",
            "ContractInfo": {
                "SwapFee": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/estimate_bridge_output",
                    "fromChain": "1",
                    "toChain": "1284",
                    "fromToken": "gOHM",
                    "toToken": "gOHM",
                    "amountFrom": ""
                },
                "BridgeApproval": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_approval_txn",
                    "params": {
                        "fromChain": "1",
                        "fromToken": "gOHM"
                    }
                },
                "BridgeTransaction": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_txn",
                    "params": {
                        "fromChain": "1",
                        "toChain": "1284",
                        "fromToken": "gOHM",
                        "toToken": "gOHM",
                        "amountFrom": "",
                        "addressTo": ""
                    }
                }
            }
        },
        "DestToken": {
            "Name": "Olympus DAO",
            "Symbol": "gOHM",
            "ContractAddress": "0xD2666441443DAa61492FFe0F37717578714a4521",
            "Decimals": 18,
            "ContractInfo": {
                "SwapFee": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/estimate_bridge_output",
                    "fromChain": "1284",
                    "toChain": "1",
                    "fromToken": "gOHM",
                    "toToken": "gOHM",
                    "amountFrom": ""
                },
                "BridgeApproval": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_approval_txn",
                    "params": {
                        "fromChain": "1284",
                        "fromToken": "gOHM"
                    }
                },
                "BridgeTransaction": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_txn",
                    "params": {
                        "fromChain": "1284",
                        "toChain": "1",
                        "fromToken": "gOHM",
                        "toToken": "gOHM",
                        "amountFrom": "",
                        "addressTo": ""
                    }
                }
            }
        }
    },
    {
        "bridge": "Synapse",
        "srcChainID": "10",
        "logoUrl": "https://assets.coingecko.com/coins/images/18024/small/syn.png",
        "name": "Synapse",
        "symbol": "SYN",
        "pairid": "syn10Synapse",
        "SrcToken": {
            "Name": "Synapse",
            "Symbol": "SYN",
            "Decimals": 18,
            "ContractAddress": "0x5A5fFf6F753d7C11A56A52FE47a177a87e431655",
            "ContractInfo": {
                "SwapFee": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/estimate_bridge_output",
                    "fromChain": "10",
                    "toChain": "1284",
                    "fromToken": "SYN",
                    "toToken": "SYN",
                    "amountFrom": ""
                },
                "BridgeApproval": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_approval_txn",
                    "params": {
                        "fromChain": "10",
                        "fromToken": "SYN"
                    }
                },
                "BridgeTransaction": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_txn",
                    "params": {
                        "fromChain": "10",
                        "toChain": "1284",
                        "fromToken": "SYN",
                        "toToken": "SYN",
                        "amountFrom": "",
                        "addressTo": ""
                    }
                }
            }
        },
        "DestToken": {
            "Name": "Synapse",
            "Symbol": "SYN",
            "ContractAddress": "0xF44938b0125A6662f9536281aD2CD6c499F22004",
            "Decimals": 18,
            "ContractInfo": {
                "SwapFee": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/estimate_bridge_output",
                    "fromChain": "1284",
                    "toChain": "10",
                    "fromToken": "SYN",
                    "toToken": "SYN",
                    "amountFrom": ""
                },
                "BridgeApproval": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_approval_txn",
                    "params": {
                        "fromChain": "1284",
                        "fromToken": "SYN"
                    }
                },
                "BridgeTransaction": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_txn",
                    "params": {
                        "fromChain": "1284",
                        "toChain": "10",
                        "fromToken": "SYN",
                        "toToken": "SYN",
                        "amountFrom": "",
                        "addressTo": ""
                    }
                }
            }
        }
    },
    {
        "bridge": "Synapse",
        "srcChainID": "10",
        "logoUrl": "https://assets.coingecko.com/coins/images/21129/small/token_wsOHM_logo.png",
        "name": "Olympus DAO",
        "symbol": "gOHM",
        "pairid": "gohm10Synapse",
        "SrcToken": {
            "Name": "Olympus DAO",
            "Symbol": "gOHM",
            "Decimals": 18,
            "ContractAddress": "0x0b5740c6b4a97f90eF2F0220651Cca420B868FfB",
            "ContractInfo": {
                "SwapFee": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/estimate_bridge_output",
                    "fromChain": "10",
                    "toChain": "1284",
                    "fromToken": "gOHM",
                    "toToken": "gOHM",
                    "amountFrom": ""
                },
                "BridgeApproval": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_approval_txn",
                    "params": {
                        "fromChain": "10",
                        "fromToken": "gOHM"
                    }
                },
                "BridgeTransaction": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_txn",
                    "params": {
                        "fromChain": "10",
                        "toChain": "1284",
                        "fromToken": "gOHM",
                        "toToken": "gOHM",
                        "amountFrom": "",
                        "addressTo": ""
                    }
                }
            }
        },
        "DestToken": {
            "Name": "Olympus DAO",
            "Symbol": "gOHM",
            "ContractAddress": "0xD2666441443DAa61492FFe0F37717578714a4521",
            "Decimals": 18,
            "ContractInfo": {
                "SwapFee": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/estimate_bridge_output",
                    "fromChain": "1284",
                    "toChain": "10",
                    "fromToken": "gOHM",
                    "toToken": "gOHM",
                    "amountFrom": ""
                },
                "BridgeApproval": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_approval_txn",
                    "params": {
                        "fromChain": "1284",
                        "fromToken": "gOHM"
                    }
                },
                "BridgeTransaction": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_txn",
                    "params": {
                        "fromChain": "1284",
                        "toChain": "10",
                        "fromToken": "gOHM",
                        "toToken": "gOHM",
                        "amountFrom": "",
                        "addressTo": ""
                    }
                }
            }
        }
    },
    {
        "bridge": "Synapse",
        "srcChainID": "25",
        "logoUrl": "https://assets.coingecko.com/coins/images/18024/small/syn.png",
        "name": "Synapse",
        "symbol": "SYN",
        "pairid": "syn25Synapse",
        "SrcToken": {
            "Name": "Synapse",
            "Symbol": "SYN",
            "Decimals": 18,
            "ContractAddress": "0xFD0F80899983b8D46152aa1717D76cba71a31616",
            "ContractInfo": {
                "SwapFee": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/estimate_bridge_output",
                    "fromChain": "25",
                    "toChain": "1284",
                    "fromToken": "SYN",
                    "toToken": "SYN",
                    "amountFrom": ""
                },
                "BridgeApproval": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_approval_txn",
                    "params": {
                        "fromChain": "25",
                        "fromToken": "SYN"
                    }
                },
                "BridgeTransaction": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_txn",
                    "params": {
                        "fromChain": "25",
                        "toChain": "1284",
                        "fromToken": "SYN",
                        "toToken": "SYN",
                        "amountFrom": "",
                        "addressTo": ""
                    }
                }
            }
        },
        "DestToken": {
            "Name": "Synapse",
            "Symbol": "SYN",
            "ContractAddress": "0xF44938b0125A6662f9536281aD2CD6c499F22004",
            "Decimals": 18,
            "ContractInfo": {
                "SwapFee": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/estimate_bridge_output",
                    "fromChain": "1284",
                    "toChain": "25",
                    "fromToken": "SYN",
                    "toToken": "SYN",
                    "amountFrom": ""
                },
                "BridgeApproval": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_approval_txn",
                    "params": {
                        "fromChain": "1284",
                        "fromToken": "SYN"
                    }
                },
                "BridgeTransaction": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_txn",
                    "params": {
                        "fromChain": "1284",
                        "toChain": "25",
                        "fromToken": "SYN",
                        "toToken": "SYN",
                        "amountFrom": "",
                        "addressTo": ""
                    }
                }
            }
        }
    },
    {
        "bridge": "Synapse",
        "srcChainID": "25",
        "logoUrl": "https://assets.coingecko.com/coins/images/21129/small/token_wsOHM_logo.png",
        "name": "Olympus DAO",
        "symbol": "gOHM",
        "pairid": "gohm25Synapse",
        "SrcToken": {
            "Name": "Olympus DAO",
            "Symbol": "gOHM",
            "Decimals": 18,
            "ContractAddress": "0xbB0A63A6CA2071c6C4bcAC11a1A317b20E3E999C",
            "ContractInfo": {
                "SwapFee": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/estimate_bridge_output",
                    "fromChain": "25",
                    "toChain": "1284",
                    "fromToken": "gOHM",
                    "toToken": "gOHM",
                    "amountFrom": ""
                },
                "BridgeApproval": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_approval_txn",
                    "params": {
                        "fromChain": "25",
                        "fromToken": "gOHM"
                    }
                },
                "BridgeTransaction": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_txn",
                    "params": {
                        "fromChain": "25",
                        "toChain": "1284",
                        "fromToken": "gOHM",
                        "toToken": "gOHM",
                        "amountFrom": "",
                        "addressTo": ""
                    }
                }
            }
        },
        "DestToken": {
            "Name": "Olympus DAO",
            "Symbol": "gOHM",
            "ContractAddress": "0xD2666441443DAa61492FFe0F37717578714a4521",
            "Decimals": 18,
            "ContractInfo": {
                "SwapFee": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/estimate_bridge_output",
                    "fromChain": "1284",
                    "toChain": "25",
                    "fromToken": "gOHM",
                    "toToken": "gOHM",
                    "amountFrom": ""
                },
                "BridgeApproval": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_approval_txn",
                    "params": {
                        "fromChain": "1284",
                        "fromToken": "gOHM"
                    }
                },
                "BridgeTransaction": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_txn",
                    "params": {
                        "fromChain": "1284",
                        "toChain": "25",
                        "fromToken": "gOHM",
                        "toToken": "gOHM",
                        "amountFrom": "",
                        "addressTo": ""
                    }
                }
            }
        }
    },
    {
        "bridge": "Synapse",
        "srcChainID": "56",
        "logoUrl": "https://assets.coingecko.com/coins/images/18024/small/syn.png",
        "name": "Synapse",
        "symbol": "SYN",
        "pairid": "syn56Synapse",
        "SrcToken": {
            "Name": "Synapse",
            "Symbol": "SYN",
            "Decimals": 18,
            "ContractAddress": "0xa4080f1778e69467e905b8d6f72f6e441f9e9484",
            "ContractInfo": {
                "SwapFee": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/estimate_bridge_output",
                    "fromChain": "56",
                    "toChain": "1284",
                    "fromToken": "SYN",
                    "toToken": "SYN",
                    "amountFrom": ""
                },
                "BridgeApproval": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_approval_txn",
                    "params": {
                        "fromChain": "56",
                        "fromToken": "SYN"
                    }
                },
                "BridgeTransaction": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_txn",
                    "params": {
                        "fromChain": "56",
                        "toChain": "1284",
                        "fromToken": "SYN",
                        "toToken": "SYN",
                        "amountFrom": "",
                        "addressTo": ""
                    }
                }
            }
        },
        "DestToken": {
            "Name": "Synapse",
            "Symbol": "SYN",
            "ContractAddress": "0xF44938b0125A6662f9536281aD2CD6c499F22004",
            "Decimals": 18,
            "ContractInfo": {
                "SwapFee": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/estimate_bridge_output",
                    "fromChain": "1284",
                    "toChain": "56",
                    "fromToken": "SYN",
                    "toToken": "SYN",
                    "amountFrom": ""
                },
                "BridgeApproval": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_approval_txn",
                    "params": {
                        "fromChain": "1284",
                        "fromToken": "SYN"
                    }
                },
                "BridgeTransaction": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_txn",
                    "params": {
                        "fromChain": "1284",
                        "toChain": "56",
                        "fromToken": "SYN",
                        "toToken": "SYN",
                        "amountFrom": "",
                        "addressTo": ""
                    }
                }
            }
        }
    },
    {
        "bridge": "Synapse",
        "srcChainID": "56",
        "logoUrl": "https://assets.coingecko.com/coins/images/21129/small/token_wsOHM_logo.png",
        "name": "Olympus DAO",
        "symbol": "gOHM",
        "pairid": "gohm56Synapse",
        "SrcToken": {
            "Name": "Olympus DAO",
            "Symbol": "gOHM",
            "Decimals": 18,
            "ContractAddress": "0x88918495892BAF4536611E38E75D771Dc6Ec0863",
            "ContractInfo": {
                "SwapFee": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/estimate_bridge_output",
                    "fromChain": "56",
                    "toChain": "1284",
                    "fromToken": "gOHM",
                    "toToken": "gOHM",
                    "amountFrom": ""
                },
                "BridgeApproval": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_approval_txn",
                    "params": {
                        "fromChain": "56",
                        "fromToken": "gOHM"
                    }
                },
                "BridgeTransaction": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_txn",
                    "params": {
                        "fromChain": "56",
                        "toChain": "1284",
                        "fromToken": "gOHM",
                        "toToken": "gOHM",
                        "amountFrom": "",
                        "addressTo": ""
                    }
                }
            }
        },
        "DestToken": {
            "Name": "Olympus DAO",
            "Symbol": "gOHM",
            "ContractAddress": "0xD2666441443DAa61492FFe0F37717578714a4521",
            "Decimals": 18,
            "ContractInfo": {
                "SwapFee": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/estimate_bridge_output",
                    "fromChain": "1284",
                    "toChain": "56",
                    "fromToken": "gOHM",
                    "toToken": "gOHM",
                    "amountFrom": ""
                },
                "BridgeApproval": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_approval_txn",
                    "params": {
                        "fromChain": "1284",
                        "fromToken": "gOHM"
                    }
                },
                "BridgeTransaction": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_txn",
                    "params": {
                        "fromChain": "1284",
                        "toChain": "56",
                        "fromToken": "gOHM",
                        "toToken": "gOHM",
                        "amountFrom": "",
                        "addressTo": ""
                    }
                }
            }
        }
    },
    {
        "bridge": "Synapse",
        "srcChainID": "137",
        "logoUrl": "https://assets.coingecko.com/coins/images/18024/small/syn.png",
        "name": "Synapse",
        "symbol": "SYN",
        "pairid": "syn137Synapse",
        "SrcToken": {
            "Name": "Synapse",
            "Symbol": "SYN",
            "Decimals": 18,
            "ContractAddress": "0xf8f9efc0db77d8881500bb06ff5d6abc3070e695",
            "ContractInfo": {
                "SwapFee": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/estimate_bridge_output",
                    "fromChain": "137",
                    "toChain": "1284",
                    "fromToken": "SYN",
                    "toToken": "SYN",
                    "amountFrom": ""
                },
                "BridgeApproval": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_approval_txn",
                    "params": {
                        "fromChain": "137",
                        "fromToken": "SYN"
                    }
                },
                "BridgeTransaction": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_txn",
                    "params": {
                        "fromChain": "137",
                        "toChain": "1284",
                        "fromToken": "SYN",
                        "toToken": "SYN",
                        "amountFrom": "",
                        "addressTo": ""
                    }
                }
            }
        },
        "DestToken": {
            "Name": "Synapse",
            "Symbol": "SYN",
            "ContractAddress": "0xF44938b0125A6662f9536281aD2CD6c499F22004",
            "Decimals": 18,
            "ContractInfo": {
                "SwapFee": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/estimate_bridge_output",
                    "fromChain": "1284",
                    "toChain": "137",
                    "fromToken": "SYN",
                    "toToken": "SYN",
                    "amountFrom": ""
                },
                "BridgeApproval": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_approval_txn",
                    "params": {
                        "fromChain": "1284",
                        "fromToken": "SYN"
                    }
                },
                "BridgeTransaction": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_txn",
                    "params": {
                        "fromChain": "1284",
                        "toChain": "137",
                        "fromToken": "SYN",
                        "toToken": "SYN",
                        "amountFrom": "",
                        "addressTo": ""
                    }
                }
            }
        }
    },
    {
        "bridge": "Synapse",
        "srcChainID": "137",
        "logoUrl": "https://assets.coingecko.com/coins/images/21129/small/token_wsOHM_logo.png",
        "name": "Olympus DAO",
        "symbol": "gOHM",
        "pairid": "gohm137Synapse",
        "SrcToken": {
            "Name": "Olympus DAO",
            "Symbol": "gOHM",
            "Decimals": 18,
            "ContractAddress": "0xd8cA34fd379d9ca3C6Ee3b3905678320F5b45195",
            "ContractInfo": {
                "SwapFee": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/estimate_bridge_output",
                    "fromChain": "137",
                    "toChain": "1284",
                    "fromToken": "gOHM",
                    "toToken": "gOHM",
                    "amountFrom": ""
                },
                "BridgeApproval": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_approval_txn",
                    "params": {
                        "fromChain": "137",
                        "fromToken": "gOHM"
                    }
                },
                "BridgeTransaction": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_txn",
                    "params": {
                        "fromChain": "137",
                        "toChain": "1284",
                        "fromToken": "gOHM",
                        "toToken": "gOHM",
                        "amountFrom": "",
                        "addressTo": ""
                    }
                }
            }
        },
        "DestToken": {
            "Name": "Olympus DAO",
            "Symbol": "gOHM",
            "ContractAddress": "0xD2666441443DAa61492FFe0F37717578714a4521",
            "Decimals": 18,
            "ContractInfo": {
                "SwapFee": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/estimate_bridge_output",
                    "fromChain": "1284",
                    "toChain": "137",
                    "fromToken": "gOHM",
                    "toToken": "gOHM",
                    "amountFrom": ""
                },
                "BridgeApproval": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_approval_txn",
                    "params": {
                        "fromChain": "1284",
                        "fromToken": "gOHM"
                    }
                },
                "BridgeTransaction": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_txn",
                    "params": {
                        "fromChain": "1284",
                        "toChain": "137",
                        "fromToken": "gOHM",
                        "toToken": "gOHM",
                        "amountFrom": "",
                        "addressTo": ""
                    }
                }
            }
        }
    },
    {
        "bridge": "Synapse",
        "srcChainID": "250",
        "logoUrl": "https://assets.coingecko.com/coins/images/18024/small/syn.png",
        "name": "Synapse",
        "symbol": "SYN",
        "pairid": "syn250Synapse",
        "SrcToken": {
            "Name": "Synapse",
            "Symbol": "SYN",
            "Decimals": 18,
            "ContractAddress": "0xE55e19Fb4F2D85af758950957714292DAC1e25B2",
            "ContractInfo": {
                "SwapFee": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/estimate_bridge_output",
                    "fromChain": "250",
                    "toChain": "1284",
                    "fromToken": "SYN",
                    "toToken": "SYN",
                    "amountFrom": ""
                },
                "BridgeApproval": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_approval_txn",
                    "params": {
                        "fromChain": "250",
                        "fromToken": "SYN"
                    }
                },
                "BridgeTransaction": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_txn",
                    "params": {
                        "fromChain": "250",
                        "toChain": "1284",
                        "fromToken": "SYN",
                        "toToken": "SYN",
                        "amountFrom": "",
                        "addressTo": ""
                    }
                }
            }
        },
        "DestToken": {
            "Name": "Synapse",
            "Symbol": "SYN",
            "ContractAddress": "0xF44938b0125A6662f9536281aD2CD6c499F22004",
            "Decimals": 18,
            "ContractInfo": {
                "SwapFee": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/estimate_bridge_output",
                    "fromChain": "1284",
                    "toChain": "250",
                    "fromToken": "SYN",
                    "toToken": "SYN",
                    "amountFrom": ""
                },
                "BridgeApproval": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_approval_txn",
                    "params": {
                        "fromChain": "1284",
                        "fromToken": "SYN"
                    }
                },
                "BridgeTransaction": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_txn",
                    "params": {
                        "fromChain": "1284",
                        "toChain": "250",
                        "fromToken": "SYN",
                        "toToken": "SYN",
                        "amountFrom": "",
                        "addressTo": ""
                    }
                }
            }
        }
    },
    {
        "bridge": "Synapse",
        "srcChainID": "250",
        "logoUrl": "https://assets.coingecko.com/coins/images/21129/small/token_wsOHM_logo.png",
        "name": "Olympus DAO",
        "symbol": "gOHM",
        "pairid": "gohm250Synapse",
        "SrcToken": {
            "Name": "Olympus DAO",
            "Symbol": "gOHM",
            "Decimals": 18,
            "ContractAddress": "0x91fa20244Fb509e8289CA630E5db3E9166233FDc",
            "ContractInfo": {
                "SwapFee": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/estimate_bridge_output",
                    "fromChain": "250",
                    "toChain": "1284",
                    "fromToken": "gOHM",
                    "toToken": "gOHM",
                    "amountFrom": ""
                },
                "BridgeApproval": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_approval_txn",
                    "params": {
                        "fromChain": "250",
                        "fromToken": "gOHM"
                    }
                },
                "BridgeTransaction": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_txn",
                    "params": {
                        "fromChain": "250",
                        "toChain": "1284",
                        "fromToken": "gOHM",
                        "toToken": "gOHM",
                        "amountFrom": "",
                        "addressTo": ""
                    }
                }
            }
        },
        "DestToken": {
            "Name": "Olympus DAO",
            "Symbol": "gOHM",
            "ContractAddress": "0xD2666441443DAa61492FFe0F37717578714a4521",
            "Decimals": 18,
            "ContractInfo": {
                "SwapFee": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/estimate_bridge_output",
                    "fromChain": "1284",
                    "toChain": "250",
                    "fromToken": "gOHM",
                    "toToken": "gOHM",
                    "amountFrom": ""
                },
                "BridgeApproval": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_approval_txn",
                    "params": {
                        "fromChain": "1284",
                        "fromToken": "gOHM"
                    }
                },
                "BridgeTransaction": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_txn",
                    "params": {
                        "fromChain": "1284",
                        "toChain": "250",
                        "fromToken": "gOHM",
                        "toToken": "gOHM",
                        "amountFrom": "",
                        "addressTo": ""
                    }
                }
            }
        }
    },
    {
        "bridge": "Synapse",
        "srcChainID": "288",
        "logoUrl": "https://assets.coingecko.com/coins/images/18024/small/syn.png",
        "name": "Synapse",
        "symbol": "SYN",
        "pairid": "syn288Synapse",
        "SrcToken": {
            "Name": "Synapse",
            "Symbol": "SYN",
            "Decimals": 18,
            "ContractAddress": "0xb554A55358fF0382Fb21F0a478C3546d1106Be8c",
            "ContractInfo": {
                "SwapFee": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/estimate_bridge_output",
                    "fromChain": "288",
                    "toChain": "1284",
                    "fromToken": "SYN",
                    "toToken": "SYN",
                    "amountFrom": ""
                },
                "BridgeApproval": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_approval_txn",
                    "params": {
                        "fromChain": "288",
                        "fromToken": "SYN"
                    }
                },
                "BridgeTransaction": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_txn",
                    "params": {
                        "fromChain": "288",
                        "toChain": "1284",
                        "fromToken": "SYN",
                        "toToken": "SYN",
                        "amountFrom": "",
                        "addressTo": ""
                    }
                }
            }
        },
        "DestToken": {
            "Name": "Synapse",
            "Symbol": "SYN",
            "ContractAddress": "0xF44938b0125A6662f9536281aD2CD6c499F22004",
            "Decimals": 18,
            "ContractInfo": {
                "SwapFee": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/estimate_bridge_output",
                    "fromChain": "1284",
                    "toChain": "288",
                    "fromToken": "SYN",
                    "toToken": "SYN",
                    "amountFrom": ""
                },
                "BridgeApproval": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_approval_txn",
                    "params": {
                        "fromChain": "1284",
                        "fromToken": "SYN"
                    }
                },
                "BridgeTransaction": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_txn",
                    "params": {
                        "fromChain": "1284",
                        "toChain": "288",
                        "fromToken": "SYN",
                        "toToken": "SYN",
                        "amountFrom": "",
                        "addressTo": ""
                    }
                }
            }
        }
    },
    {
        "bridge": "Synapse",
        "srcChainID": "288",
        "logoUrl": "https://assets.coingecko.com/coins/images/21129/small/token_wsOHM_logo.png",
        "name": "Olympus DAO",
        "symbol": "gOHM",
        "pairid": "gohm288Synapse",
        "SrcToken": {
            "Name": "Olympus DAO",
            "Symbol": "gOHM",
            "Decimals": 18,
            "ContractAddress": "0xd22C0a4Af486C7FA08e282E9eB5f30F9AaA62C95",
            "ContractInfo": {
                "SwapFee": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/estimate_bridge_output",
                    "fromChain": "288",
                    "toChain": "1284",
                    "fromToken": "gOHM",
                    "toToken": "gOHM",
                    "amountFrom": ""
                },
                "BridgeApproval": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_approval_txn",
                    "params": {
                        "fromChain": "288",
                        "fromToken": "gOHM"
                    }
                },
                "BridgeTransaction": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_txn",
                    "params": {
                        "fromChain": "288",
                        "toChain": "1284",
                        "fromToken": "gOHM",
                        "toToken": "gOHM",
                        "amountFrom": "",
                        "addressTo": ""
                    }
                }
            }
        },
        "DestToken": {
            "Name": "Olympus DAO",
            "Symbol": "gOHM",
            "ContractAddress": "0xD2666441443DAa61492FFe0F37717578714a4521",
            "Decimals": 18,
            "ContractInfo": {
                "SwapFee": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/estimate_bridge_output",
                    "fromChain": "1284",
                    "toChain": "288",
                    "fromToken": "gOHM",
                    "toToken": "gOHM",
                    "amountFrom": ""
                },
                "BridgeApproval": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_approval_txn",
                    "params": {
                        "fromChain": "1284",
                        "fromToken": "gOHM"
                    }
                },
                "BridgeTransaction": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_txn",
                    "params": {
                        "fromChain": "1284",
                        "toChain": "288",
                        "fromToken": "gOHM",
                        "toToken": "gOHM",
                        "amountFrom": "",
                        "addressTo": ""
                    }
                }
            }
        }
    },
    {
        "bridge": "Synapse",
        "srcChainID": "1088",
        "logoUrl": "https://assets.coingecko.com/coins/images/18024/small/syn.png",
        "name": "Synapse",
        "symbol": "SYN",
        "pairid": "syn1088Synapse",
        "SrcToken": {
            "Name": "Synapse",
            "Symbol": "SYN",
            "Decimals": 18,
            "ContractAddress": "0x67C10C397dD0Ba417329543c1a40eb48AAa7cd00",
            "ContractInfo": {
                "SwapFee": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/estimate_bridge_output",
                    "fromChain": "1088",
                    "toChain": "1284",
                    "fromToken": "SYN",
                    "toToken": "SYN",
                    "amountFrom": ""
                },
                "BridgeApproval": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_approval_txn",
                    "params": {
                        "fromChain": "1088",
                        "fromToken": "SYN"
                    }
                },
                "BridgeTransaction": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_txn",
                    "params": {
                        "fromChain": "1088",
                        "toChain": "1284",
                        "fromToken": "SYN",
                        "toToken": "SYN",
                        "amountFrom": "",
                        "addressTo": ""
                    }
                }
            }
        },
        "DestToken": {
            "Name": "Synapse",
            "Symbol": "SYN",
            "ContractAddress": "0xF44938b0125A6662f9536281aD2CD6c499F22004",
            "Decimals": 18,
            "ContractInfo": {
                "SwapFee": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/estimate_bridge_output",
                    "fromChain": "1284",
                    "toChain": "1088",
                    "fromToken": "SYN",
                    "toToken": "SYN",
                    "amountFrom": ""
                },
                "BridgeApproval": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_approval_txn",
                    "params": {
                        "fromChain": "1284",
                        "fromToken": "SYN"
                    }
                },
                "BridgeTransaction": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_txn",
                    "params": {
                        "fromChain": "1284",
                        "toChain": "1088",
                        "fromToken": "SYN",
                        "toToken": "SYN",
                        "amountFrom": "",
                        "addressTo": ""
                    }
                }
            }
        }
    },
    {
        "bridge": "Synapse",
        "srcChainID": "1088",
        "logoUrl": "https://assets.coingecko.com/coins/images/21129/small/token_wsOHM_logo.png",
        "name": "Olympus DAO",
        "symbol": "gOHM",
        "pairid": "gohm1088Synapse",
        "SrcToken": {
            "Name": "Olympus DAO",
            "Symbol": "gOHM",
            "Decimals": 18,
            "ContractAddress": "0xFB21B70922B9f6e3C6274BcD6CB1aa8A0fe20B80",
            "ContractInfo": {
                "SwapFee": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/estimate_bridge_output",
                    "fromChain": "1088",
                    "toChain": "1284",
                    "fromToken": "gOHM",
                    "toToken": "gOHM",
                    "amountFrom": ""
                },
                "BridgeApproval": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_approval_txn",
                    "params": {
                        "fromChain": "1088",
                        "fromToken": "gOHM"
                    }
                },
                "BridgeTransaction": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_txn",
                    "params": {
                        "fromChain": "1088",
                        "toChain": "1284",
                        "fromToken": "gOHM",
                        "toToken": "gOHM",
                        "amountFrom": "",
                        "addressTo": ""
                    }
                }
            }
        },
        "DestToken": {
            "Name": "Olympus DAO",
            "Symbol": "gOHM",
            "ContractAddress": "0xD2666441443DAa61492FFe0F37717578714a4521",
            "Decimals": 18,
            "ContractInfo": {
                "SwapFee": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/estimate_bridge_output",
                    "fromChain": "1284",
                    "toChain": "1088",
                    "fromToken": "gOHM",
                    "toToken": "gOHM",
                    "amountFrom": ""
                },
                "BridgeApproval": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_approval_txn",
                    "params": {
                        "fromChain": "1284",
                        "fromToken": "gOHM"
                    }
                },
                "BridgeTransaction": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_txn",
                    "params": {
                        "fromChain": "1284",
                        "toChain": "1088",
                        "fromToken": "gOHM",
                        "toToken": "gOHM",
                        "amountFrom": "",
                        "addressTo": ""
                    }
                }
            }
        }
    },
    {
        "bridge": "Synapse",
        "srcChainID": "1285",
        "logoUrl": "https://assets.coingecko.com/coins/images/18024/small/syn.png",
        "name": "Synapse",
        "symbol": "SYN",
        "pairid": "syn1285Synapse",
        "SrcToken": {
            "Name": "Synapse",
            "Symbol": "SYN",
            "Decimals": 18,
            "ContractAddress": "0xd80d8688b02B3FD3afb81cDb124F188BB5aD0445",
            "ContractInfo": {
                "SwapFee": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/estimate_bridge_output",
                    "fromChain": "1285",
                    "toChain": "1284",
                    "fromToken": "SYN",
                    "toToken": "SYN",
                    "amountFrom": ""
                },
                "BridgeApproval": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_approval_txn",
                    "params": {
                        "fromChain": "1285",
                        "fromToken": "SYN"
                    }
                },
                "BridgeTransaction": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_txn",
                    "params": {
                        "fromChain": "1285",
                        "toChain": "1284",
                        "fromToken": "SYN",
                        "toToken": "SYN",
                        "amountFrom": "",
                        "addressTo": ""
                    }
                }
            }
        },
        "DestToken": {
            "Name": "Synapse",
            "Symbol": "SYN",
            "ContractAddress": "0xF44938b0125A6662f9536281aD2CD6c499F22004",
            "Decimals": 18,
            "ContractInfo": {
                "SwapFee": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/estimate_bridge_output",
                    "fromChain": "1284",
                    "toChain": "1285",
                    "fromToken": "SYN",
                    "toToken": "SYN",
                    "amountFrom": ""
                },
                "BridgeApproval": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_approval_txn",
                    "params": {
                        "fromChain": "1284",
                        "fromToken": "SYN"
                    }
                },
                "BridgeTransaction": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_txn",
                    "params": {
                        "fromChain": "1284",
                        "toChain": "1285",
                        "fromToken": "SYN",
                        "toToken": "SYN",
                        "amountFrom": "",
                        "addressTo": ""
                    }
                }
            }
        }
    },
    {
        "bridge": "Synapse",
        "srcChainID": "1285",
        "logoUrl": "https://assets.coingecko.com/coins/images/21129/small/token_wsOHM_logo.png",
        "name": "Olympus DAO",
        "symbol": "gOHM",
        "pairid": "gohm1285Synapse",
        "SrcToken": {
            "Name": "Olympus DAO",
            "Symbol": "gOHM",
            "Decimals": 18,
            "ContractAddress": "0x3bF21Ce864e58731B6f28D68d5928BcBEb0Ad172",
            "ContractInfo": {
                "SwapFee": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/estimate_bridge_output",
                    "fromChain": "1285",
                    "toChain": "1284",
                    "fromToken": "gOHM",
                    "toToken": "gOHM",
                    "amountFrom": ""
                },
                "BridgeApproval": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_approval_txn",
                    "params": {
                        "fromChain": "1285",
                        "fromToken": "gOHM"
                    }
                },
                "BridgeTransaction": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_txn",
                    "params": {
                        "fromChain": "1285",
                        "toChain": "1284",
                        "fromToken": "gOHM",
                        "toToken": "gOHM",
                        "amountFrom": "",
                        "addressTo": ""
                    }
                }
            }
        },
        "DestToken": {
            "Name": "Olympus DAO",
            "Symbol": "gOHM",
            "ContractAddress": "0xD2666441443DAa61492FFe0F37717578714a4521",
            "Decimals": 18,
            "ContractInfo": {
                "SwapFee": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/estimate_bridge_output",
                    "fromChain": "1284",
                    "toChain": "1285",
                    "fromToken": "gOHM",
                    "toToken": "gOHM",
                    "amountFrom": ""
                },
                "BridgeApproval": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_approval_txn",
                    "params": {
                        "fromChain": "1284",
                        "fromToken": "gOHM"
                    }
                },
                "BridgeTransaction": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_txn",
                    "params": {
                        "fromChain": "1284",
                        "toChain": "1285",
                        "fromToken": "gOHM",
                        "toToken": "gOHM",
                        "amountFrom": "",
                        "addressTo": ""
                    }
                }
            }
        }
    },
    {
        "bridge": "Synapse",
        "srcChainID": "1285",
        "logoUrl": "https://assets.coingecko.com/coins/images/18260/small/solarbeamlogo.png",
        "name": "Vested SolarBeam",
        "symbol": "veSOLAR",
        "pairid": "vesolar1285Synapse",
        "SrcToken": {
            "Name": "Vested SolarBeam",
            "Symbol": "veSOLAR",
            "Decimals": 18,
            "ContractAddress": "0x76906411D07815491A5E577022757aD941fb5066",
            "ContractInfo": {
                "SwapFee": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/estimate_bridge_output",
                    "fromChain": "1285",
                    "toChain": "1284",
                    "fromToken": "veSOLAR",
                    "toToken": "veSOLAR",
                    "amountFrom": ""
                },
                "BridgeApproval": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_approval_txn",
                    "params": {
                        "fromChain": "1285",
                        "fromToken": "veSOLAR"
                    }
                },
                "BridgeTransaction": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_txn",
                    "params": {
                        "fromChain": "1285",
                        "toChain": "1284",
                        "fromToken": "veSOLAR",
                        "toToken": "veSOLAR",
                        "amountFrom": "",
                        "addressTo": ""
                    }
                }
            }
        },
        "DestToken": {
            "Name": "Vested SolarBeam",
            "Symbol": "veSOLAR",
            "ContractAddress": "0x0DB6729C03C85B0708166cA92801BcB5CAc781fC",
            "Decimals": 18,
            "ContractInfo": {
                "SwapFee": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/estimate_bridge_output",
                    "fromChain": "1284",
                    "toChain": "1285",
                    "fromToken": "veSOLAR",
                    "toToken": "veSOLAR",
                    "amountFrom": ""
                },
                "BridgeApproval": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_approval_txn",
                    "params": {
                        "fromChain": "1284",
                        "fromToken": "veSOLAR"
                    }
                },
                "BridgeTransaction": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_txn",
                    "params": {
                        "fromChain": "1284",
                        "toChain": "1285",
                        "fromToken": "veSOLAR",
                        "toToken": "veSOLAR",
                        "amountFrom": "",
                        "addressTo": ""
                    }
                }
            }
        }
    },
    {
        "bridge": "Synapse",
        "srcChainID": "1285",
        "logoUrl": "https://assets.coingecko.com/coins/images/17984/small/9285.png",
        "name": "Moonriver",
        "symbol": "MOVR",
        "pairid": "movr1285Synapse",
        "SrcToken": {
            "Name": "Moonriver",
            "Symbol": "MOVR",
            "Decimals": 18,
            "ContractAddress": "",
            "ContractInfo": {
                "SwapFee": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/estimate_bridge_output",
                    "fromChain": "1285",
                    "toChain": "1284",
                    "fromToken": "MOVR",
                    "toToken": "wMOVR",
                    "amountFrom": ""
                },
                "BridgeTransaction": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_txn",
                    "params": {
                        "fromChain": "1285",
                        "toChain": "1284",
                        "fromToken": "MOVR",
                        "toToken": "wMOVR",
                        "amountFrom": "",
                        "addressTo": ""
                    }
                }
            }
        },
        "DestToken": {
            "Name": "Wrapped MOVR",
            "Symbol": "wMOVR",
            "ContractAddress": "0x1d4C2a246311bB9f827F4C768e277FF5787B7D7E",
            "Decimals": 18,
            "ContractInfo": {
                "SwapFee": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/estimate_bridge_output",
                    "fromChain": "1284",
                    "toChain": "1285",
                    "fromToken": "wMOVR",
                    "toToken": "MOVR",
                    "amountFrom": ""
                },
                "BridgeApproval": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_approval_txn",
                    "params": {
                        "fromChain": "1284",
                        "fromToken": "wMOVR"
                    }
                },
                "BridgeTransaction": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_txn",
                    "params": {
                        "fromChain": "1284",
                        "toChain": "1285",
                        "fromToken": "wMOVR",
                        "toToken": "MOVR",
                        "amountFrom": "",
                        "addressTo": ""
                    }
                }
            }
        }
    },
    {
        "bridge": "Synapse",
        "srcChainID": "42161",
        "logoUrl": "https://assets.coingecko.com/coins/images/18024/small/syn.png",
        "name": "Synapse",
        "symbol": "SYN",
        "pairid": "syn42161Synapse",
        "SrcToken": {
            "Name": "Synapse",
            "Symbol": "SYN",
            "Decimals": 18,
            "ContractAddress": "0x080f6aed32fc474dd5717105dba5ea57268f46eb",
            "ContractInfo": {
                "SwapFee": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/estimate_bridge_output",
                    "fromChain": "42161",
                    "toChain": "1284",
                    "fromToken": "SYN",
                    "toToken": "SYN",
                    "amountFrom": ""
                },
                "BridgeApproval": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_approval_txn",
                    "params": {
                        "fromChain": "42161",
                        "fromToken": "SYN"
                    }
                },
                "BridgeTransaction": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_txn",
                    "params": {
                        "fromChain": "42161",
                        "toChain": "1284",
                        "fromToken": "SYN",
                        "toToken": "SYN",
                        "amountFrom": "",
                        "addressTo": ""
                    }
                }
            }
        },
        "DestToken": {
            "Name": "Synapse",
            "Symbol": "SYN",
            "ContractAddress": "0xF44938b0125A6662f9536281aD2CD6c499F22004",
            "Decimals": 18,
            "ContractInfo": {
                "SwapFee": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/estimate_bridge_output",
                    "fromChain": "1284",
                    "toChain": "42161",
                    "fromToken": "SYN",
                    "toToken": "SYN",
                    "amountFrom": ""
                },
                "BridgeApproval": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_approval_txn",
                    "params": {
                        "fromChain": "1284",
                        "fromToken": "SYN"
                    }
                },
                "BridgeTransaction": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_txn",
                    "params": {
                        "fromChain": "1284",
                        "toChain": "42161",
                        "fromToken": "SYN",
                        "toToken": "SYN",
                        "amountFrom": "",
                        "addressTo": ""
                    }
                }
            }
        }
    },
    {
        "bridge": "Synapse",
        "srcChainID": "42161",
        "logoUrl": "https://assets.coingecko.com/coins/images/21129/small/token_wsOHM_logo.png",
        "name": "Olympus DAO",
        "symbol": "gOHM",
        "pairid": "gohm42161Synapse",
        "SrcToken": {
            "Name": "Olympus DAO",
            "Symbol": "gOHM",
            "Decimals": 18,
            "ContractAddress": "0x8D9bA570D6cb60C7e3e0F31343Efe75AB8E65FB1",
            "ContractInfo": {
                "SwapFee": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/estimate_bridge_output",
                    "fromChain": "42161",
                    "toChain": "1284",
                    "fromToken": "gOHM",
                    "toToken": "gOHM",
                    "amountFrom": ""
                },
                "BridgeApproval": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_approval_txn",
                    "params": {
                        "fromChain": "42161",
                        "fromToken": "gOHM"
                    }
                },
                "BridgeTransaction": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_txn",
                    "params": {
                        "fromChain": "42161",
                        "toChain": "1284",
                        "fromToken": "gOHM",
                        "toToken": "gOHM",
                        "amountFrom": "",
                        "addressTo": ""
                    }
                }
            }
        },
        "DestToken": {
            "Name": "Olympus DAO",
            "Symbol": "gOHM",
            "ContractAddress": "0xD2666441443DAa61492FFe0F37717578714a4521",
            "Decimals": 18,
            "ContractInfo": {
                "SwapFee": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/estimate_bridge_output",
                    "fromChain": "1284",
                    "toChain": "42161",
                    "fromToken": "gOHM",
                    "toToken": "gOHM",
                    "amountFrom": ""
                },
                "BridgeApproval": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_approval_txn",
                    "params": {
                        "fromChain": "1284",
                        "fromToken": "gOHM"
                    }
                },
                "BridgeTransaction": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_txn",
                    "params": {
                        "fromChain": "1284",
                        "toChain": "42161",
                        "fromToken": "gOHM",
                        "toToken": "gOHM",
                        "amountFrom": "",
                        "addressTo": ""
                    }
                }
            }
        }
    },
    {
        "bridge": "Synapse",
        "srcChainID": "43114",
        "logoUrl": "https://assets.coingecko.com/coins/images/18024/small/syn.png",
        "name": "Synapse",
        "symbol": "SYN",
        "pairid": "syn43114Synapse",
        "SrcToken": {
            "Name": "Synapse",
            "Symbol": "SYN",
            "Decimals": 18,
            "ContractAddress": "0x1f1E7c893855525b303f99bDF5c3c05Be09ca251",
            "ContractInfo": {
                "SwapFee": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/estimate_bridge_output",
                    "fromChain": "43114",
                    "toChain": "1284",
                    "fromToken": "SYN",
                    "toToken": "SYN",
                    "amountFrom": ""
                },
                "BridgeApproval": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_approval_txn",
                    "params": {
                        "fromChain": "43114",
                        "fromToken": "SYN"
                    }
                },
                "BridgeTransaction": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_txn",
                    "params": {
                        "fromChain": "43114",
                        "toChain": "1284",
                        "fromToken": "SYN",
                        "toToken": "SYN",
                        "amountFrom": "",
                        "addressTo": ""
                    }
                }
            }
        },
        "DestToken": {
            "Name": "Synapse",
            "Symbol": "SYN",
            "ContractAddress": "0xF44938b0125A6662f9536281aD2CD6c499F22004",
            "Decimals": 18,
            "ContractInfo": {
                "SwapFee": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/estimate_bridge_output",
                    "fromChain": "1284",
                    "toChain": "43114",
                    "fromToken": "SYN",
                    "toToken": "SYN",
                    "amountFrom": ""
                },
                "BridgeApproval": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_approval_txn",
                    "params": {
                        "fromChain": "1284",
                        "fromToken": "SYN"
                    }
                },
                "BridgeTransaction": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_txn",
                    "params": {
                        "fromChain": "1284",
                        "toChain": "43114",
                        "fromToken": "SYN",
                        "toToken": "SYN",
                        "amountFrom": "",
                        "addressTo": ""
                    }
                }
            }
        }
    },
    {
        "bridge": "Synapse",
        "srcChainID": "43114",
        "logoUrl": "https://assets.coingecko.com/coins/images/21129/small/token_wsOHM_logo.png",
        "name": "Olympus DAO",
        "symbol": "gOHM",
        "pairid": "gohm43114Synapse",
        "SrcToken": {
            "Name": "Olympus DAO",
            "Symbol": "gOHM",
            "Decimals": 18,
            "ContractAddress": "0x321E7092a180BB43555132ec53AaA65a5bF84251",
            "ContractInfo": {
                "SwapFee": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/estimate_bridge_output",
                    "fromChain": "43114",
                    "toChain": "1284",
                    "fromToken": "gOHM",
                    "toToken": "gOHM",
                    "amountFrom": ""
                },
                "BridgeApproval": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_approval_txn",
                    "params": {
                        "fromChain": "43114",
                        "fromToken": "gOHM"
                    }
                },
                "BridgeTransaction": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_txn",
                    "params": {
                        "fromChain": "43114",
                        "toChain": "1284",
                        "fromToken": "gOHM",
                        "toToken": "gOHM",
                        "amountFrom": "",
                        "addressTo": ""
                    }
                }
            }
        },
        "DestToken": {
            "Name": "Olympus DAO",
            "Symbol": "gOHM",
            "ContractAddress": "0xD2666441443DAa61492FFe0F37717578714a4521",
            "Decimals": 18,
            "ContractInfo": {
                "SwapFee": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/estimate_bridge_output",
                    "fromChain": "1284",
                    "toChain": "43114",
                    "fromToken": "gOHM",
                    "toToken": "gOHM",
                    "amountFrom": ""
                },
                "BridgeApproval": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_approval_txn",
                    "params": {
                        "fromChain": "1284",
                        "fromToken": "gOHM"
                    }
                },
                "BridgeTransaction": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_txn",
                    "params": {
                        "fromChain": "1284",
                        "toChain": "43114",
                        "fromToken": "gOHM",
                        "toToken": "gOHM",
                        "amountFrom": "",
                        "addressTo": ""
                    }
                }
            }
        }
    },
    {
        "bridge": "Synapse",
        "srcChainID": "43114",
        "logoUrl": "https://assets.coingecko.com/coins/images/12559/small/coin-round-red.png",
        "name": "Avalanche",
        "symbol": "AVAX",
        "pairid": "avax43114Synapse",
        "SrcToken": {
            "Name": "Avalanche",
            "Symbol": "AVAX",
            "Decimals": 18,
            "ContractAddress": "",
            "ContractInfo": {
                "SwapFee": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/estimate_bridge_output",
                    "fromChain": "43114",
                    "toChain": "1284",
                    "fromToken": "AVAX",
                    "toToken": "wAVAX",
                    "amountFrom": ""
                },
                "BridgeTransaction": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_txn",
                    "params": {
                        "fromChain": "43114",
                        "toChain": "1284",
                        "fromToken": "AVAX",
                        "toToken": "wAVAX",
                        "amountFrom": "",
                        "addressTo": ""
                    }
                }
            }
        },
        "DestToken": {
            "Name": "Wrapped AVAX",
            "Symbol": "wAVAX",
            "ContractAddress": "0xA1f8890E39b4d8E33efe296D698fe42Fb5e59cC3",
            "Decimals": 18,
            "ContractInfo": {
                "SwapFee": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/estimate_bridge_output",
                    "fromChain": "1284",
                    "toChain": "43114",
                    "fromToken": "wAVAX",
                    "toToken": "AVAX",
                    "amountFrom": ""
                },
                "BridgeApproval": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_approval_txn",
                    "params": {
                        "fromChain": "1284",
                        "fromToken": "wAVAX"
                    }
                },
                "BridgeTransaction": {
                    "baseUrl": "https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41813a12-6cd0-49c0-abb9-68838a3e1f30/default/generate_unsigned_bridge_txn",
                    "params": {
                        "fromChain": "1284",
                        "toChain": "43114",
                        "fromToken": "wAVAX",
                        "toToken": "AVAX",
                        "amountFrom": "",
                        "addressTo": ""
                    }
                }
            }
        }
    },
    {
        "srcChainID": "1",
        "destChainID": "1284",
        "logoUrl": "https://assets.coingecko.com/coins/images/12645/large/AAVE.png",
        "name": "AaveToken",
        "symbol": "AAVE",
        "pairid": "aave1Multichain",
        "SrcToken": {
            "ID": "ProxyERC20",
            "Name": "AAVEERC20",
            "Symbol": "AAVE",
            "Decimals": 18,
            "Description": "ERC20 AAVE",
            "ContractAddress": "0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9",
            "DisableSwap": false,
            "IsDelegateContract": false,
            "BaseFeePercent": 0,
            "ContractInfo": {
                "DepositAddress": "0xEC4486a90371c9b66f499Ff3936F29f0D5AF8b7E",
                "DcrmAddress": "0xEC4486a90371c9b66f499Ff3936F29f0D5AF8b7E",
                "MaximumSwap": 24000,
                "MinimumSwap": 0.4,
                "BigValueThreshold": 4700,
                "SwapFeeRate": 0,
                "MaximumSwapFee": 0,
                "MinimumSwapFee": 0,
                "PlusGasPricePercentage": 10,
                "routerABI": "transfer(toAddress,amount)"
            }
        },
        "DestToken": {
            "ID": "AAVE",
            "Name": "Aave Token",
            "Symbol": "AAVE",
            "Decimals": 18,
            "Description": "cross chain bridge AAVE with AAVE",
            "ContractAddress": "0x461d52769884ca6235b685ef2040f47d30c94eb5",
            "DisableSwap": false,
            "IsDelegateContract": false,
            "BaseFeePercent": 0,
            "ContractInfo": {
                "DcrmAddress": "0xEC4486a90371c9b66f499Ff3936F29f0D5AF8b7E",
                "MaximumSwap": 24000,
                "MinimumSwap": 0.94,
                "BigValueThreshold": 4700,
                "SwapFeeRate": 0.001,
                "MaximumSwapFee": 4.7,
                "MinimumSwapFee": 0.4,
                "PlusGasPricePercentage": 1,
                "routerABI": "Swapout(amount,toAddress)"
            }
        },
        "bridge": "Multichain"
    },
    {
        "srcChainID": "1",
        "destChainID": "1284",
        "logoUrl": "https://assets.coingecko.com/coins/images/12124/large/Curve.png",
        "name": "CurveDAOToken",
        "symbol": "CRV",
        "pairid": "crv1Multichain",
        "SrcToken": {
            "ID": "ERC20",
            "Name": "CRVERC20",
            "Symbol": "CRV",
            "Decimals": 18,
            "Description": "ERC20 CRV",
            "ContractAddress": "0xD533a949740bb3306d119CC777fa900bA034cd52",
            "DisableSwap": false,
            "IsDelegateContract": false,
            "BaseFeePercent": 0,
            "ContractInfo": {
                "DepositAddress": "0xEC4486a90371c9b66f499Ff3936F29f0D5AF8b7E",
                "DcrmAddress": "0xEC4486a90371c9b66f499Ff3936F29f0D5AF8b7E",
                "MaximumSwap": 2300000,
                "MinimumSwap": 17.4,
                "BigValueThreshold": 450000,
                "SwapFeeRate": 0,
                "MaximumSwapFee": 0,
                "MinimumSwapFee": 0,
                "PlusGasPricePercentage": 10,
                "routerABI": "transfer(toAddress,amount)"
            }
        },
        "DestToken": {
            "ID": "CRV",
            "Name": "Curve DAO Token",
            "Symbol": "CRV",
            "Decimals": 18,
            "Description": "cross chain bridge CRV with CRV",
            "ContractAddress": "0x7c598c96d02398d89fbcb9d41eab3df0c16f227d",
            "DisableSwap": false,
            "IsDelegateContract": false,
            "BaseFeePercent": 0,
            "ContractInfo": {
                "DcrmAddress": "0xEC4486a90371c9b66f499Ff3936F29f0D5AF8b7E",
                "MaximumSwap": 2300000,
                "MinimumSwap": 44,
                "BigValueThreshold": 450000,
                "SwapFeeRate": 0.001,
                "MaximumSwapFee": 218,
                "MinimumSwapFee": 17.4,
                "PlusGasPricePercentage": 1,
                "routerABI": "Swapout(amount,toAddress)"
            }
        },
        "bridge": "Multichain"
    },
    {
        "srcChainID": "1",
        "destChainID": "1284",
        "logoUrl": "https://assets.coingecko.com/coins/images/9956/large/4943.png",
        "name": "DaiStablecoin",
        "symbol": "DAI",
        "pairid": "dai1Multichain",
        "SrcToken": {
            "ID": "ERC20",
            "Name": "DAIERC20",
            "Symbol": "DAI",
            "Decimals": 18,
            "Description": "ERC20 DAI",
            "ContractAddress": "0x6B175474E89094C44Da98b954EedeAC495271d0F",
            "DisableSwap": false,
            "IsDelegateContract": false,
            "BaseFeePercent": 0,
            "ContractInfo": {
                "DepositAddress": "0xEC4486a90371c9b66f499Ff3936F29f0D5AF8b7E",
                "DcrmAddress": "0xEC4486a90371c9b66f499Ff3936F29f0D5AF8b7E",
                "MaximumSwap": 5000000,
                "MinimumSwap": 40,
                "BigValueThreshold": 1000000,
                "SwapFeeRate": 0,
                "MaximumSwapFee": 0,
                "MinimumSwapFee": 0,
                "PlusGasPricePercentage": 10,
                "routerABI": "transfer(toAddress,amount)"
            }
        },
        "DestToken": {
            "ID": "DAI",
            "Name": "Dai Stablecoin",
            "Symbol": "DAI",
            "Decimals": 18,
            "Description": "cross chain bridge DAI with DAI",
            "ContractAddress": "0x765277eebeca2e31912c9946eae1021199b39c61",
            "DisableSwap": false,
            "IsDelegateContract": false,
            "BaseFeePercent": 0,
            "ContractInfo": {
                "DcrmAddress": "0xEC4486a90371c9b66f499Ff3936F29f0D5AF8b7E",
                "MaximumSwap": 5000000,
                "MinimumSwap": 200,
                "BigValueThreshold": 1000000,
                "SwapFeeRate": 0.001,
                "MaximumSwapFee": 1000,
                "MinimumSwapFee": 40,
                "PlusGasPricePercentage": 1,
                "routerABI": "Swapout(amount,toAddress)"
            }
        },
        "bridge": "Multichain"
    },
    {
        "srcChainID": "1",
        "destChainID": "1284",
        "logoUrl": "https://assets.coingecko.com/coins/images/279/large/ethereum.png",
        "name": "Ethereum",
        "symbol": "ETH",
        "pairid": "eth1Multichain",
        "SrcToken": {
            "ID": "ETH",
            "Name": "Ethereum Coin",
            "Symbol": "ETH",
            "Decimals": 18,
            "Description": "Ethereum Coin",
            "DisableSwap": false,
            "IsDelegateContract": false,
            "BaseFeePercent": 0,
            "ContractInfo": {
                "DepositAddress": "0xEC4486a90371c9b66f499Ff3936F29f0D5AF8b7E",
                "DcrmAddress": "0xEC4486a90371c9b66f499Ff3936F29f0D5AF8b7E",
                "MaximumSwap": 1580,
                "MinimumSwap": 0.017,
                "BigValueThreshold": 320,
                "SwapFeeRate": 0,
                "MaximumSwapFee": 0,
                "MinimumSwapFee": 0,
                "PlusGasPricePercentage": 10,
                "routerABI": ""
            }
        },
        "DestToken": {
            "ID": "ETH",
            "Name": "Ethereum",
            "Symbol": "ETH",
            "Decimals": 18,
            "Description": "cross chain bridge ETH with ETH",
            "ContractAddress": "0xfa9343c3897324496a05fc75abed6bac29f8a40f",
            "DisableSwap": false,
            "IsDelegateContract": false,
            "BaseFeePercent": 0,
            "ContractInfo": {
                "DcrmAddress": "0xEC4486a90371c9b66f499Ff3936F29f0D5AF8b7E",
                "MaximumSwap": 1580,
                "MinimumSwap": 0.05,
                "BigValueThreshold": 320,
                "SwapFeeRate": 0.001,
                "MaximumSwapFee": 0.22,
                "MinimumSwapFee": 0.014,
                "PlusGasPricePercentage": 1,
                "routerABI": "Swapout(amount,toAddress)"
            }
        },
        "bridge": "Multichain"
    },
    {
        "srcChainID": "1",
        "destChainID": "1284",
        "logoUrl": "https://assets.coingecko.com/coins/images/12271/large/512x512_Logo_no_chop.png",
        "name": "SushiToken",
        "symbol": "SUSHI",
        "pairid": "sushi1Multichain",
        "SrcToken": {
            "ID": "ERC20",
            "Name": "SUSHIERC20",
            "Symbol": "SUSHI",
            "Decimals": 18,
            "Description": "ERC20 SUSHI",
            "ContractAddress": "0x6b3595068778dd592e39a122f4f5a5cf09c90fe2",
            "DisableSwap": false,
            "IsDelegateContract": false,
            "BaseFeePercent": 0,
            "ContractInfo": {
                "DepositAddress": "0xEC4486a90371c9b66f499Ff3936F29f0D5AF8b7E",
                "DcrmAddress": "0xEC4486a90371c9b66f499Ff3936F29f0D5AF8b7E",
                "MaximumSwap": 720000,
                "MinimumSwap": 11.4,
                "BigValueThreshold": 150000,
                "SwapFeeRate": 0,
                "MaximumSwapFee": 0,
                "MinimumSwapFee": 0,
                "PlusGasPricePercentage": 10,
                "routerABI": "transfer(toAddress,amount)"
            }
        },
        "DestToken": {
            "ID": "SUSHI",
            "Name": "SushiToken",
            "Symbol": "SUSHI",
            "Decimals": 18,
            "Description": "cross chain bridge SUSHI with SUSHI",
            "ContractAddress": "0x2c78f1b70ccf63cdee49f9233e9faa99d43aa07e",
            "DisableSwap": false,
            "IsDelegateContract": false,
            "BaseFeePercent": 0,
            "ContractInfo": {
                "DcrmAddress": "0xEC4486a90371c9b66f499Ff3936F29f0D5AF8b7E",
                "MaximumSwap": 720000,
                "MinimumSwap": 28.5,
                "BigValueThreshold": 150000,
                "SwapFeeRate": 0.001,
                "MaximumSwapFee": 142,
                "MinimumSwapFee": 11.4,
                "PlusGasPricePercentage": 1,
                "routerABI": "Swapout(amount,toAddress)"
            }
        },
        "bridge": "Multichain"
    },
    {
        "srcChainID": "1",
        "destChainID": "1284",
        "logoUrl": "https://assets.coingecko.com/coins/images/6319/large/USD_Coin_icon.png",
        "name": "USDCoin",
        "symbol": "USDC",
        "pairid": "usdc1Multichain",
        "SrcToken": {
            "ID": "ProxyERC20",
            "Name": "USDCERC20",
            "Symbol": "USDC",
            "Decimals": 6,
            "Description": "ERC20 USDC",
            "ContractAddress": "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
            "DisableSwap": false,
            "IsDelegateContract": false,
            "BaseFeePercent": 0,
            "ContractInfo": {
                "DepositAddress": "0xEC4486a90371c9b66f499Ff3936F29f0D5AF8b7E",
                "DcrmAddress": "0xEC4486a90371c9b66f499Ff3936F29f0D5AF8b7E",
                "MaximumSwap": 5000000,
                "MinimumSwap": 40,
                "BigValueThreshold": 1000000,
                "SwapFeeRate": 0,
                "MaximumSwapFee": 0,
                "MinimumSwapFee": 0,
                "PlusGasPricePercentage": 10,
                "routerABI": "transfer(toAddress,amount)"
            }
        },
        "DestToken": {
            "ID": "USDC",
            "Name": "USD Coin",
            "Symbol": "USDC",
            "Decimals": 6,
            "Description": "cross chain bridge USDC with USDC",
            "ContractAddress": "0x818ec0a7fe18ff94269904fced6ae3dae6d6dc0b",
            "DisableSwap": false,
            "IsDelegateContract": false,
            "BaseFeePercent": 0,
            "ContractInfo": {
                "DcrmAddress": "0xEC4486a90371c9b66f499Ff3936F29f0D5AF8b7E",
                "MaximumSwap": 5000000,
                "MinimumSwap": 200,
                "BigValueThreshold": 1000000,
                "SwapFeeRate": 0.001,
                "MaximumSwapFee": 1000,
                "MinimumSwapFee": 40,
                "PlusGasPricePercentage": 1,
                "routerABI": "Swapout(amount,toAddress)"
            }
        },
        "bridge": "Multichain"
    },
    {
        "srcChainID": "1",
        "destChainID": "1284",
        "logoUrl": "https://assets.coingecko.com/coins/images/325/large/Tether-logo.png",
        "name": "TetherUSD",
        "symbol": "USDT",
        "pairid": "usdt1Multichain",
        "SrcToken": {
            "ID": "ERC20",
            "Name": "USDTERC20",
            "Symbol": "USDT",
            "Decimals": 6,
            "Description": "ERC20 USDT",
            "ContractAddress": "0xdac17f958d2ee523a2206206994597c13d831ec7",
            "DisableSwap": false,
            "IsDelegateContract": false,
            "BaseFeePercent": 0,
            "ContractInfo": {
                "DepositAddress": "0xEC4486a90371c9b66f499Ff3936F29f0D5AF8b7E",
                "DcrmAddress": "0xEC4486a90371c9b66f499Ff3936F29f0D5AF8b7E",
                "MaximumSwap": 5000000,
                "MinimumSwap": 40,
                "BigValueThreshold": 1000000,
                "SwapFeeRate": 0,
                "MaximumSwapFee": 0,
                "MinimumSwapFee": 0,
                "PlusGasPricePercentage": 10,
                "routerABI": "transfer(toAddress,amount)"
            }
        },
        "DestToken": {
            "ID": "USDT",
            "Name": "Tether USD",
            "Symbol": "USDT",
            "Decimals": 6,
            "Description": "cross chain bridge USDT with USDT",
            "ContractAddress": "0xefaeee334f0fd1712f9a8cc375f427d9cdd40d73",
            "DisableSwap": false,
            "IsDelegateContract": false,
            "BaseFeePercent": 0,
            "ContractInfo": {
                "DcrmAddress": "0xEC4486a90371c9b66f499Ff3936F29f0D5AF8b7E",
                "MaximumSwap": 5000000,
                "MinimumSwap": 200,
                "BigValueThreshold": 1000000,
                "SwapFeeRate": 0.001,
                "MaximumSwapFee": 1000,
                "MinimumSwapFee": 40,
                "PlusGasPricePercentage": 1,
                "routerABI": "Swapout(amount,toAddress)"
            }
        },
        "bridge": "Multichain"
    },
    {
        "srcChainID": "1",
        "destChainID": "1284",
        "logoUrl": "https://assets.coingecko.com/coins/images/1/large/bitcoin.png",
        "name": "WrappedBTC",
        "symbol": "WBTC",
        "pairid": "wbtc1Multichain",
        "SrcToken": {
            "ID": "ERC20",
            "Name": "WBTCERC20",
            "Symbol": "WBTC",
            "Decimals": 8,
            "Description": "ERC20 WBTC",
            "ContractAddress": "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
            "DisableSwap": false,
            "IsDelegateContract": false,
            "BaseFeePercent": 0,
            "ContractInfo": {
                "DepositAddress": "0xEC4486a90371c9b66f499Ff3936F29f0D5AF8b7E",
                "DcrmAddress": "0xEC4486a90371c9b66f499Ff3936F29f0D5AF8b7E",
                "MaximumSwap": 101,
                "MinimumSwap": 0.0012,
                "BigValueThreshold": 20.2,
                "SwapFeeRate": 0,
                "MaximumSwapFee": 0,
                "MinimumSwapFee": 0,
                "PlusGasPricePercentage": 10,
                "routerABI": "transfer(toAddress,amount)"
            }
        },
        "DestToken": {
            "ID": "WBTC",
            "Name": "Wrapped BTC",
            "Symbol": "WBTC",
            "Decimals": 8,
            "Description": "cross chain bridge WBTC with WBTC",
            "ContractAddress": "0x922d641a426dcffaef11680e5358f34d97d112e1",
            "DisableSwap": false,
            "IsDelegateContract": false,
            "BaseFeePercent": 0,
            "ContractInfo": {
                "DcrmAddress": "0xEC4486a90371c9b66f499Ff3936F29f0D5AF8b7E",
                "MaximumSwap": 101,
                "MinimumSwap": 0.03,
                "BigValueThreshold": 20.2,
                "SwapFeeRate": 0.001,
                "MaximumSwapFee": 0.015,
                "MinimumSwapFee": 0.0012,
                "PlusGasPricePercentage": 1,
                "routerABI": "Swapout(amount,toAddress)"
            }
        },
        "bridge": "Multichain"
    },
    {
        "srcChainID": "56",
        "destChainID": "1284",
        "logoUrl": "https://assets.coingecko.com/coins/images/825/large/bnb-icon2_2x.png",
        "name": "Binance",
        "symbol": "BNB",
        "pairid": "bnb56Multichain",
        "SrcToken": {
            "ID": "BNB",
            "Name": "Binance",
            "Symbol": "BNB",
            "Decimals": 18,
            "Description": "Binance",
            "DisableSwap": false,
            "IsDelegateContract": false,
            "BaseFeePercent": 0,
            "ContractInfo": {
                "DepositAddress": "0xd9B4aE62721d6311d67566A32E75f9002447922e",
                "DcrmAddress": "0xd9B4aE62721d6311d67566A32E75f9002447922e",
                "MaximumSwap": 11000,
                "MinimumSwap": 0.1,
                "BigValueThreshold": 2200,
                "SwapFeeRate": 0,
                "MaximumSwapFee": 0,
                "MinimumSwapFee": 0,
                "PlusGasPricePercentage": 10,
                "routerABI": ""
            }
        },
        "DestToken": {
            "ID": "BNB",
            "Name": "Binance",
            "Symbol": "BNB",
            "Decimals": 18,
            "Description": "cross chain bridge BNB with BNB",
            "ContractAddress": "0xc9baa8cfdde8e328787e29b4b078abf2dadc2055",
            "DisableSwap": false,
            "IsDelegateContract": false,
            "BaseFeePercent": 0,
            "ContractInfo": {
                "DcrmAddress": "0xd9B4aE62721d6311d67566A32E75f9002447922e",
                "MaximumSwap": 11000,
                "MinimumSwap": 0.1,
                "BigValueThreshold": 2200,
                "SwapFeeRate": 0.001,
                "MaximumSwapFee": 2.2,
                "MinimumSwapFee": 0.011,
                "PlusGasPricePercentage": 1,
                "routerABI": "Swapout(amount,toAddress)"
            }
        },
        "bridge": "Multichain"
    },
    {
        "srcChainID": "56",
        "destChainID": "1284",
        "logoUrl": "https://assets.coingecko.com/coins/images/9576/large/BUSD.png",
        "name": "BUSDToken",
        "symbol": "BUSD",
        "pairid": "busd56Multichain",
        "SrcToken": {
            "ID": "ERC20",
            "Name": "BUSDERC20",
            "Symbol": "BUSD",
            "Decimals": 18,
            "Description": "ERC20 BUSD",
            "ContractAddress": "0xe9e7cea3dedca5984780bafc599bd69add087d56",
            "DisableSwap": false,
            "IsDelegateContract": false,
            "BaseFeePercent": 0,
            "ContractInfo": {
                "DepositAddress": "0xd9B4aE62721d6311d67566A32E75f9002447922e",
                "DcrmAddress": "0xd9B4aE62721d6311d67566A32E75f9002447922e",
                "MaximumSwap": 10000000,
                "MinimumSwap": 50,
                "BigValueThreshold": 2000000,
                "SwapFeeRate": 0,
                "MaximumSwapFee": 0,
                "MinimumSwapFee": 0,
                "PlusGasPricePercentage": 10,
                "routerABI": "transfer(toAddress,amount)"
            }
        },
        "DestToken": {
            "ID": "BUSD",
            "Name": "Binance-Peg BUSD Token",
            "Symbol": "BUSD",
            "Decimals": 18,
            "Description": "cross chain bridge BUSD with BUSD",
            "ContractAddress": "0xa649325aa7c5093d12d6f98eb4378deae68ce23f",
            "DisableSwap": false,
            "IsDelegateContract": false,
            "BaseFeePercent": 0,
            "ContractInfo": {
                "DcrmAddress": "0xd9B4aE62721d6311d67566A32E75f9002447922e",
                "MaximumSwap": 10000000,
                "MinimumSwap": 50,
                "BigValueThreshold": 2000000,
                "SwapFeeRate": 0.001,
                "MaximumSwapFee": 1000,
                "MinimumSwapFee": 5,
                "PlusGasPricePercentage": 1,
                "routerABI": "Swapout(amount,toAddress)"
            }
        },
        "bridge": "Multichain"
    },
    {
        "srcChainID": "137",
        "destChainID": "1284",
        "logoUrl": "https://assets.coingecko.com/coins/images/4713/large/matic-token-icon.png",
        "name": "Polygon",
        "symbol": "MATIC",
        "pairid": "matic137Multichain",
        "SrcToken": {
            "ID": "MATIC",
            "Name": "Matic",
            "Symbol": "MATIC",
            "Decimals": 18,
            "Description": "Matic",
            "DisableSwap": false,
            "IsDelegateContract": false,
            "BaseFeePercent": 0,
            "ContractInfo": {
                "DepositAddress": "0xFf3259029830ddeDaDBc104056Ec73B176EE1c3e",
                "DcrmAddress": "0xFf3259029830ddeDaDBc104056Ec73B176EE1c3e",
                "MaximumSwap": 2100000,
                "MinimumSwap": 2.1,
                "BigValueThreshold": 420000,
                "SwapFeeRate": 0,
                "MaximumSwapFee": 0,
                "MinimumSwapFee": 0,
                "PlusGasPricePercentage": 10,
                "routerABI": ""
            }
        },
        "DestToken": {
            "ID": "MATIC",
            "Name": "Matic",
            "Symbol": "MATIC",
            "Decimals": 18,
            "Description": "cross chain bridge MATIC with MATIC",
            "ContractAddress": "0x3405a1bd46b85c5c029483fbecf2f3e611026e45",
            "DisableSwap": false,
            "IsDelegateContract": false,
            "BaseFeePercent": 0,
            "ContractInfo": {
                "DcrmAddress": "0xFf3259029830ddeDaDBc104056Ec73B176EE1c3e",
                "MaximumSwap": 2100000,
                "MinimumSwap": 21,
                "BigValueThreshold": 420000,
                "SwapFeeRate": 0.001,
                "MaximumSwapFee": 420,
                "MinimumSwapFee": 2.1,
                "PlusGasPricePercentage": 1,
                "routerABI": "Swapout(amount,toAddress)"
            }
        },
        "bridge": "Multichain"
    },
    {
        "srcChainID": "43114",
        "destChainID": "1284",
        "logoUrl": "https://assets.coingecko.com/coins/images/12559/large/coin-round-red.png",
        "name": "Avalanche",
        "symbol": "AVAX",
        "pairid": "avax43114Multichain",
        "SrcToken": {
            "ID": "AVAX",
            "Name": "Avalanche",
            "Symbol": "AVAX",
            "Decimals": 18,
            "Description": "Avalanche",
            "DisableSwap": false,
            "IsDelegateContract": false,
            "BaseFeePercent": 0,
            "ContractInfo": {
                "DepositAddress": "0x4E13c3c78094E60Cef02D87fD266CD7AeD415F17",
                "DcrmAddress": "0x4E13c3c78094E60Cef02D87fD266CD7AeD415F17",
                "MaximumSwap": 56000,
                "MinimumSwap": 0.06,
                "BigValueThreshold": 12000,
                "SwapFeeRate": 0,
                "MaximumSwapFee": 0,
                "MinimumSwapFee": 0,
                "PlusGasPricePercentage": 10,
                "routerABI": ""
            }
        },
        "DestToken": {
            "ID": "AVAX",
            "Name": "Avalanche",
            "Symbol": "AVAX",
            "Decimals": 18,
            "Description": "cross chain bridge AVAX with AVAX",
            "ContractAddress": "0x4792c1ecb969b036eb51330c63bd27899a13d84e",
            "DisableSwap": false,
            "IsDelegateContract": false,
            "BaseFeePercent": 0,
            "ContractInfo": {
                "DcrmAddress": "0x4E13c3c78094E60Cef02D87fD266CD7AeD415F17",
                "MaximumSwap": 56000,
                "MinimumSwap": 0.6,
                "BigValueThreshold": 12000,
                "SwapFeeRate": 0.001,
                "MaximumSwapFee": 11.2,
                "MinimumSwapFee": 0.06,
                "PlusGasPricePercentage": 1,
                "routerABI": "Swapout(amount,toAddress)"
            }
        },
        "bridge": "Multichain"
    }
]

export default response;